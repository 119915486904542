import * as THREE from 'three';
import React, { RefObject } from 'react';
import { ThemeAwareComponent } from './Themeable';

import './MyThree.scss';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import { InfiniteGridHelper } from 't2m-playground/src/utils/InfiniteGridHelper';
import { Model } from 't2m-playground/src/Model';

import { ListBladeApi, ButtonApi, Pane } from 'tweakpane';
import * as EssentialsPlugin from '@tweakpane/plugin-essentials';

import 't2m-playground/src/index.css';
import { SequencerPane } from 't2m-playground/src/SequencerPane';

import CookieContext from './CookieContext';

// Define Props and State interfaces
interface MyThreeProps {
}

const GROUND_Y_OFFSET = -0.3;
const SCENE_BACKGROUND_COLOR = 0x000000;
const DIRECTIONAL_LIGHT1_POSITION = { x: 3, y: 4, z: 10 };

// TODO: Use either joyride or reactour to create a guided tour for the user

class MyThree extends ThemeAwareComponent<MyThreeProps> {
    private refContainer: RefObject<HTMLDivElement>;
    private renderer: THREE.WebGLRenderer;
    private scene: THREE.Scene;
    private camera: THREE.PerspectiveCamera;
    private controls: OrbitControls;
    private model: Model | undefined;
    private pane: Pane | undefined;
	private settings = {
		model: "male_robot",
		skeleton: false,
		aabb: false,
		autoRotate: true,
        autoPlay: true,
	};

    // Specify the context type
    static contextType = CookieContext;
    context!: React.ContextType<typeof CookieContext>;
    private previousContextValue: React.ContextType<typeof CookieContext> | null = null;

    private observer: MutationObserver;

    private resizeWindow() {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
    }

    private updateScene() {
        const time = performance.now(); // Get the current time in milliseconds        
        this.controls.update(); // Update the camera orbit control
    }

    private animate() {
        this.updateScene(); // Update the scene
        this.renderer.render(this.scene, this.camera);
        requestAnimationFrame(this.animate.bind(this)); // Request the next frame
    }

    private convertToThreeColor(color: string): THREE.Color
    {
        const threeColor = new THREE.Color();
      
        // Check if the color is in rgb or rgba format
        if (color.startsWith('rgb')) {
          const rgbValues = color.match(/\d+/g);
          if (rgbValues) {
            const [r, g, b] = rgbValues.map(Number);
            threeColor.setRGB(r / 255, g / 255, b / 255);
          }
        } else {
          // Assume the color is in hex format
          threeColor.set(color);
        }
      
        return threeColor;
    };    

    override onThemeChanged() {
        const color = this.convertToThreeColor(getComputedStyle(document.documentElement).getPropertyValue(`--three-bg-${this.getTheme()}`).trim());
        this.scene.background = color
        this.scene.fog = new THREE.FogExp2(color, 0.03);
    }

    constructor(props: MyThreeProps) {
        super(props);
        this.refContainer = React.createRef<HTMLDivElement>();

        const width = window.innerWidth;
        const height = window.innerHeight;

        // Create a WebGL renderer and set its size to match the window
        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize(width, height);
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;

        this.scene = new THREE.Scene();
        const backgroundColor = this.convertToThreeColor(getComputedStyle(document.documentElement).getPropertyValue(`--three-bg-${this.getTheme()}`).trim())
        this.scene.background = backgroundColor;
        this.scene.fog = new THREE.FogExp2(backgroundColor, 0.03);

        // Create an infinite grid helper with a size of 1, 2, white color, and distance of 100
        const grid = new InfiniteGridHelper(0.5, 2, new THREE.Color('gray'), 100, GROUND_Y_OFFSET);
        this.scene.add(grid);

        var geo = new THREE.PlaneGeometry(120, 120);
        var mat = new THREE.MeshPhongMaterial({ color: 0xFFFFFF });
        mat.depthTest = false;
        var plane = new THREE.Mesh(geo, mat);
        plane.translateY(GROUND_Y_OFFSET);
        plane.rotateX(-Math.PI / 2);
        plane.receiveShadow = true;
        plane.renderOrder = -1000;
        this.scene.add(plane);

        // Create a perspective camera with a field of view of 60 degrees, aspect ratio based on window size, and near/far clipping planes
        this.camera = new THREE.PerspectiveCamera(60, width / height, 0.01, 1000);
        // Set the camera position to (0, 0, 5) and make it look at the origin
        this.camera.position.z = 5;
        this.camera.position.y = 2;

        // Setup OrbitControls for the camera
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableDamping = true;
        this.controls.autoRotate = true;

        // Update the camera aspect ratio when the window is resized
        window.addEventListener('resize', this.resizeWindow.bind(this));

        // Create a point light and position it at (0, 0, 2)
        const light = new THREE.PointLight(0xffffff, 1, 1000);
        light.position.set(0, 3, 4);
        light.intensity = 30;
        light.castShadow = true;
        // scene.add(new THREE.CameraHelper(light.shadow.camera));
        light.shadow.mapSize.width = 2048;
        light.shadow.mapSize.height = 2048;
        // light.shadow.bias = -0.1;

        // Create a point light and position it at (0, 0, 2)
        const fillLight = new THREE.PointLight(0xffffff, 1, 1000);
        fillLight.position.set(0, 2.5, -3);
        fillLight.intensity = 8;
        this.scene.add(fillLight);

        var light1 = new THREE.DirectionalLight(0xffffff, 1.0);
        light1.position.set(DIRECTIONAL_LIGHT1_POSITION.x, DIRECTIONAL_LIGHT1_POSITION.y, DIRECTIONAL_LIGHT1_POSITION.z);
        // light1.castShadow = true;
        // light1.shadow.mapSize.width = 1024;
        // light1.shadow.mapSize.height = 1024;
        this.scene.add(light1);

        // Add the point light to the scene
        this.scene.add(light);

        // Create an ambient light with a soft white color
        const ambientLight = new THREE.AmbientLight(0x404040);
        ambientLight.intensity = 5;

        // Add the ambient light to the scene
        this.scene.add(ambientLight);

        // Render the scene with the camera
        this.renderer.render(this.scene, this.camera);

        // Initialize the MutationObserver
        this.observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
                    const target = mutation.target as HTMLElement;
                    const zIndex = window.getComputedStyle(target).zIndex;
                    this.onZIndexChanged(zIndex);
                }
            });
        });

        const settings = {
            model: "male_robot",
            skeleton: false,
            aabb: false,
            autoRotate: true,
        };
    }
    onZIndexChanged(zIndex: string) {
        if (this.pane && this.pane.element.parentElement)
            switch (zIndex) {
                case '0':
                    this.pane.element.parentElement.classList.add('show');

                    break;
                case '-1':
                    this.pane.element.parentElement.classList.remove('show');
                    break
            }
    }

    componentDidUpdate(): void {
        if (this.context !== this.previousContextValue) {
            this.previousContextValue = this.context;
            this.onContextChanged();
        }     
    }

    private onContextChanged() {
        if (this.model && this.context)
        {
            this.model.apiKey = this.context.cookieData.playgroundApiKey;
            this.model.apiEndpoint = this.context.cookieData.apiEndpoint;
        }
    }

    showModal() {
        const ebModal = document.getElementById('modal_message');
        if (ebModal)
            ebModal.style.display = 'block';
    }
    closeModal() {
        const ebModal = document.getElementById('modal_message');
        if (ebModal)
            ebModal.style.display = 'none';
    }

    componentDidMount() {
        document.body.setAttribute('data-theme', this.getTheme());
        localStorage.setItem('theme', this.getTheme());
        this.forceUpdate();
        this.onThemeChanged();

        if (this.refContainer.current) {
            this.refContainer.current.appendChild(this.renderer.domElement);
            this.observer.observe(this.refContainer.current, {
                attributes: true,
                attributeFilter: ['style'],
            });
        }

        this.pane = new Pane();
        this.pane.element.parentElement?.classList.add('fade');
        this.pane.registerPlugin(EssentialsPlugin);
        const tabApi = this.pane.addTab({
            pages: [
                { title: "Animation" },
                { title: "Settings" }
            ]
        })
        const sequencerPage = new SequencerPane(tabApi.pages[0], this.settings);
        const settingsPage = tabApi.pages[1];

        this.model = new Model(sequencerPage);
        this.model.onBusy = this.showModal;
        this.model.onDone = this.closeModal;

        this.onContextChanged(); // So that initial context is set

        // model.onBusy = showModal;
        // model.onDone = closeModal;
        this.scene.add(this.model);

        const sceneFolder = settingsPage.addFolder({ title: "Scene" });
        
        // This is actually going in the SequencerPane
        const models_list = tabApi.pages[0].addBlade({
            view: "list",
            label: "Model",
            index: 0,
            options: [
                { text: "Male Robot", value: "assets/CHR_R_Maxim.glb" },
                { text: "Female Robot", value: "assets/X_Bot_mixamo.glb" },
                { text: "Human", value: "assets/sophie.glb" },
                { text: "Zombie", value: "assets/zombie.glb" },
                { text: "Custom", value: "" }
            ],
            value: "assets/X_Bot_mixamo.glb",
        }) as ListBladeApi<string>;

        // Create a hidden file input element
        const fileInput = document.createElement('input');
        fileInput.title = 'Select a GLTF or GLB model to upload';
        fileInput.type = 'file';
        fileInput.accept = '.glb,.gltf'; // Accept GLB and GLTF files
        fileInput.style.visibility = 'hidden';
        fileInput.style.height = '0px';
        fileInput.style.position = 'absolute';
        fileInput.style.zIndex = "-500";
        document.body.appendChild(fileInput);

        let resetModelButton: ButtonApi | null = null;
        
        models_list
            .on('change', async (ev) => {
                // TODO: Show a dialog to confirm if the user wants to reset the model
                sequencerPage.reset();
                
                if (ev.value === "") {
                        resetModelButton = tabApi.pages[0].addButton({
                            title: "Reset",
                            index: 1
                        });
                        resetModelButton.on('click', async () => {
                            models_list.value = "assets/CHR_R_Maxim.glb";
                            if (resetModelButton)
                                tabApi.pages[0].remove(resetModelButton);
                        });
                        fileInput.onchange = async (event) => {
                        const files = fileInput.files;
                        if (files && files.length > 0) {
                            const url = URL.createObjectURL(files[0]);
                            await this.model!.load(url);
                            if (this.model!.skeleton)
                                this.model!.skeleton.visible = this.settings.skeleton;
                            this.model!.aabb.visible = this.settings.aabb;
                        }
                    };
                    fileInput.click();
                }
                else
                {
                    if (resetModelButton)
                        tabApi.pages[0].remove(resetModelButton);
                    await this.model!.load(ev.value);
                    if (this.model!.skeleton)
                        this.model!.skeleton.visible = this.settings.skeleton;
                    this.model!.aabb.visible = this.settings.aabb;
                }
            });
        models_list.value = "assets/CHR_R_Maxim.glb";

        sceneFolder.addBinding(this.settings, 'autoRotate', { label: "Auto rotate" })
		.on('change', (ev) => {
			this.controls.autoRotate = ev.value;
		});
        sceneFolder.addBinding(this.settings, 'autoPlay', { label: "Auto play" });

        const debugFolder = settingsPage.addFolder({ title: "Debug" });

        debugFolder.addBinding(this.settings, 'skeleton', { label: "Skeleton" })
            .on('change', (ev) => {
                if (this.model!.skeleton)
                    this.model!.skeleton.visible = ev.value;
                else {
                    console.warn("Skeleton not found");
                    this.settings.skeleton = false;
                }
            });
        debugFolder.addBinding(this.settings, 'aabb', { label: "Bounds" })
            .on('change', (ev) => {
                if (this.model!.aabb)
                    this.model!.aabb.visible = ev.value;
                else {
                    console.warn("AABB not found");
                    this.settings.aabb = false;
                }
            });

        this.animate();
    }

    render() {
        return <div>
            <div id="modal_message" className="modal_container">
                <div className="modal-content">
                    <div className="loader">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </div>
                </div>
            </div>

            <div className="toasts"></div>
            <div className="master-toast-notification hide-toast">
                <div className="toast-content">
                    <div className="toast-icon">
                        <i className="fa-solid"></i>
                    </div>
                    <div className="toast-msg"></div>
                </div>
                <div className="toast-progress">
                    <div className="toast-progress-bar"></div>
                </div>
            </div>	

            <div className={this.constructor.name} ref={this.refContainer} id={this.constructor.name} />
        </div>;
    }
}

export default MyThree;