import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import MyThree from "./components/Mythree";

import "./index.scss";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Route, Routes, BrowserRouter, HashRouter } from "react-router-dom";

import { Home } from "./components/Home";
import { Faq } from "./components/Faq";

import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import NavLinks from "./components/NavLinks";

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    <HashRouter>
      <App>
        <Header>
          <NavLinks className="NavLinks center" />
        </Header>
        <MyThree />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/faq" element={<Faq />} />
          </Routes>
        <Footer />
      </App>
    </HashRouter>
  );
}
