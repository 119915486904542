import React from 'react';
import { Tile, TileProps } from './Tile';

import './VideoTile.scss';

interface VideoTileProps extends TileProps {
    videoSrc: string;
    description?: string;
};

class VideoTile extends Tile<VideoTileProps> {
    constructor(props: VideoTileProps) {
        super(props);
    }

    render() {
        let { width, height, videoSrc, description } = this.props as VideoTileProps;
        const style: React.CSSProperties = {
            width: `${width}`,
            height: `${height}`
        };

        return (
            <div className={this.getClassName()} style={style}>
                <video src={videoSrc} autoPlay muted loop playsInline/>
                {
                    description ?
                    <div>
                        <p>{description}</p>
                    </div> :
                    ""
                }
                
            </div>
        );
    }
}

export default VideoTile;