import React, { Component } from "react";
import { TileManager } from "./TileManager";
import ContentTile from "./ContentTile";
import { Link } from "react-router-dom";

import "./Faq.scss";

interface FaqProps {
};

export class Faq extends Component<FaqProps> {
    constructor(props: FaqProps) {
        super(props);
    }

    render() {

        return (
            <div className="faqPageContainer">
                <TileManager
                    orientation="vertical"
                    width="100vw"
                    height="auto"
                    background="transparent"
                    root={true}
                >
                    <TileManager orientation="horizontal" height="4rem" />
                    <TileManager
                        orientation="vertical"
                        width="100vw"
                        height="auto"
                    >
                        <ContentTile width="100%" height="100%">
                            <h1><em><u>Frequently Asked Questions</u></em></h1>
                            <p>Here are some of the most frequently asked questions about Text2Motion. Please <Link to="/#Contact">contact us</Link> if you cannot find the answer to your question here!</p>
                            <ul>
                                <li id="what-is-t2m">
                                    <b><em>What is Text2Motion?</em></b>
                                    <br/>
                                    Text2Motion is a tool that allows you to generate 3D animations from text prompts. It uses a generative AI model to generate animations for a given prompt.
                                </li>
                                <li id="how-to-use">
                                    <b><em>How do I use Text2Motion?</em></b>
                                    <br/>
                                    Simply type in a prompt in the text box and click the "film-strip" icon (or enter) to see the animation. You can also customize the model, and download the animation (coming soon!).
                                </li>
                                <li id="t2m-help-animator">
                                    <b><em>I'm an animator, how can Text2Motion help me?</em></b>
                                    <br/>
                                    Text2Motion can help you quickly prototype animations, or generate animations for a given prompt. You can also use it to generate animations for characters or objects that you may not have experience animating. Think of us as your animation Copilot, a force-multiplier for your creativity!
                                </li>
                                <li id="t2m-help-executive">
                                    <b><em>I'm a Game/Animation Studio Executive, how can Text2Motion help us?</em></b>
                                    <br/>
                                    Text2Motion can help your team quickly generate animations in-engine or in your animation software. It can reduce the time it takes to create animations by an order of magnitude, increasing the productivity of your team and allowing them to focus on more creative tasks.
                                </li>
                                <li id="t2m-difference-sora">
                                    <b><em>How is Text2Motion different from AI video generators like Sora?</em></b>
                                    <br/>
                                    Text2Motion is specifically designed for generating 3D animations, while tools like Sora generate videos. 3D models can be customized and animated in real-time, whereas the AI generated videos are pre-rendered and cannot be customized in  real-time.
                                </li>
                                <li id="use-custom-models">
                                    <b><em>Can I use my own 3D models with Text2Motion?</em></b>
                                    <br/>
                                    Yes! Use the "Custom" option and upload your own model. Note that it does have to be a Mixamo rig in order to function properly in the web playground.
                                </li>
                                <li id="how-download-animations">
                                    <b><em>How can I download animations from the web playground?</em></b>
                                    <br/>
                                    This feature is coming soon, watch out for updates, or join our Discord server for the latest news!
                                </li>
                                <li id="prompt-issues">
                                    <b><em>My prompt, "Do something" - isn't working as I expect!</em></b>
                                    <br/>
                                    Since the preview model is trained on a limited dataset, it may not be able to perform every action. Try using variations of the prompt, or let us know about this! We are constantly adding new data to improve the model and would love to hear your feedback.
                                </li>
                            </ul>
                        </ContentTile>
                    </TileManager>
                </TileManager>
            </div>
        )
    }
}